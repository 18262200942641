import { ChangeEvent, useState } from 'react';

import { useTheme } from '@mui/material';

import { Common } from 'src/features/JobsTable/JobsTable';
import { useAppSelector } from 'src/utilities/hooks';

type UseJobsTableProps = Pick<Common, 'rows'>;

export function useJobsTable({ rows }: UseJobsTableProps) {
  const theme = useTheme();

  const [isDense, setIsDense] = useState(true);

  const userDeleteRights = useAppSelector((state) => state.user.rights.delete);
  const canTablePossiblyHaveADeletableRow = rows.some(({ jobType }) =>
    userDeleteRights.includes(`job-${jobType}`),
  );

  const userInsertRights = useAppSelector((state) => state.user.rights.insert);
  const doesTableHaveACopyableRow = rows.some(({ jobType }) =>
    userInsertRights.includes(`job-${jobType}`),
  );

  function handleChangeTableDensity(e: ChangeEvent<HTMLInputElement>) {
    setIsDense(e.target.checked);
  }

  return {
    canTablePossiblyHaveADeletableRow,
    doesTableHaveACopyableRow,
    handleChangeTableDensity,
    isDense,
    theme,
  };
}
