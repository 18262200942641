import { useState } from 'react';

import _ from 'lodash';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardActions,
  Checkbox,
  Skeleton,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  setContentListInitialValues,
  transformJobContentToRemove,
} from 'src/features/Content/content.utils';
import { WaveIcon } from 'src/features/WaveIcon';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch, useAppSelector, useExpand, useRouteParams } from 'src/utilities/hooks';
import { parseRecordToLogicalObject } from '../../../../utilities/parsers';
import {
  useGetCategoriesQuery,
  useGetContentListQuery,
  useGetLanguagesQuery,
  useUpdateContentMutation,
} from '../../content.service';
import { ActionBar } from '../ActionBar';
import { StatusIcon } from '../StatusIcon';

import type { JobContentItem } from '../../Content.types';

const CategoryContent = styled(Box)`
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
`;

export function ContentList() {
  const { age, jobId, jobType } = useRouteParams();
  const userId = useAppSelector((state) => state.user.details.id);
  const dispatch = useAppDispatch();

  const [contentIds, setContentIds] = useState<number[]>([]);

  const [updateContent, { isLoading: isUpdatingContent }] = useUpdateContentMutation();

  const { data: contents, isFetching: isContentListFetching } = useGetContentListQuery<{
    data: JobContentItem[];
    isFetching: boolean;
  }>(
    {
      age,
      jobId,
      src: jobType,
    },
    { refetchOnMountOrArgChange: true },
  );

  const contentList = setContentListInitialValues(contents, 'category_id');

  const { data: languages, isFetching: areLanguagesFetching } = useGetLanguagesQuery(
    {
      age,
      jobId,
      src: jobType,
    },
    { refetchOnMountOrArgChange: true },
  );

  const { data: categoriesList = [], isFetching: areCategoriesFetching } = useGetCategoriesQuery(
    {},
    { refetchOnMountOrArgChange: true },
  );

  const { areAllItemsExpanded, expandedItems, toggleExpandAll, toggleExpandItem } = useExpand(
    parseRecordToLogicalObject(_.groupBy(contents, 'category_id')),
  );

  function renderCategoryItem(item: JobContentItem, index: number) {
    return (
      <Grid key={index} xs={4}>
        <Card>
          <Box
            component={CardActions}
            justifyContent="space-between"
            p={0}
            sx={{ backgroundColor: 'var(--filler) !important' }}
          >
            <Box alignItems="center" display="flex" pl={0.5} pt={0}>
              <Checkbox
                checked={contentIds.includes(item.content_id)}
                onChange={(e: any) => {
                  const contentId = item.content_id;

                  if (e.target.checked) {
                    setContentIds([...contentIds, contentId]);
                  } else {
                    setContentIds(contentIds.filter((contentId) => contentId !== contentId));
                  }
                }}
              />

              <Typography textTransform="capitalize">{languages?.[item.language]}</Typography>
            </Box>

            <Box alignItems="center" display="flex">
              <StatusIcon status={item.status} />
            </Box>
          </Box>

          <CategoryContent dangerouslySetInnerHTML={{ __html: item.content }} />
        </Card>
      </Grid>
    );
  }

  function handleRemoveContent() {
    const jobContentList = transformJobContentToRemove(contents, contentIds);

    const payload = {
      age,
      contents: jobContentList,
      id: userId,
      jobid: jobId,
      src: jobType,
    };

    updateContent(payload)
      .unwrap()
      .then(() => {
        setContentIds([]);
        toggleExpandAll(false);
        dispatch(
          openWaveSnack({
            message: 'Content removed successfully',
            type: 'success',
          }),
        );
      });
  }

  function handleClickOnSelectAll() {
    const jobContentIds = contents.map((content) => content.content_id);

    if (contentIds.length > 0 && contentIds.length === jobContentIds.length) {
      setContentIds([]);
    } else {
      setContentIds(jobContentIds);
    }
  }

  return (
    <Box p={2}>
      <ActionBar
        areAllCategoriesExpanded={areAllItemsExpanded}
        areAllCategoriesSelected={contentIds.length > 0 && contentIds.length === contents.length}
        isRemoveContentDisabled={!contentIds.length || isUpdatingContent}
        onExpandAllClick={toggleExpandAll}
        onRemoveContent={handleRemoveContent}
        onSelectAllClick={handleClickOnSelectAll}
      />

      {!languages ||
      !categoriesList.length ||
      !contentList ||
      isContentListFetching ||
      areLanguagesFetching ||
      areCategoriesFetching
        ? [...Array(3)].map((_, index) => (
            <Box key={index} py={0.5}>
              <Skeleton height={30} variant="rounded" />
            </Box>
          ))
        : contentList.map((content, index) => {
            const categoryId = content.category_id;
            const category = categoriesList.find((cat) => cat.id === Number(categoryId));

            return (
              <Accordion
                expanded={expandedItems[categoryId] || false}
                key={`${categoryId}-${index}`}
              >
                <AccordionSummary
                  expandIcon={<WaveIcon code="expand-more" sx={{ color: 'white' }} />}
                  id={`${categoryId}-${index}`}
                  onClick={() => toggleExpandItem(categoryId)}
                  sx={{
                    backgroundColor: 'var(--primary) !important',
                    borderTopLeftRadius: '4px !important',
                    borderTopRightRadius: '4px !important',
                    color: 'white !important',
                    height: '35px !important',
                    minHeight: '35px !important',
                  }}
                >
                  {(content?.totalCount as number) > 1
                    ? `${category?.value} ${content?.index}`
                    : category?.value}
                </AccordionSummary>

                <Box component={AccordionDetails} p={1}>
                  <Grid container justifyContent="stretch" m={0} spacing={2}>
                    {renderCategoryItem(content, index)}
                  </Grid>
                </Box>
              </Accordion>
            );
          })}
    </Box>
  );
}
