import { IsNotSub, Main, Sub } from 'src/features/JobsTable/components/Header/Header';
import { usePreferencePrefix } from 'src/utilities/hooks';
import { usePagePreference } from 'src/utilities/hooks/usePagePreference';
import { usePreferenceAsNumber } from 'src/utilities/hooks/usePreferenceAsNumber';

type useHeaderProps = Omit<IsNotSub, 'isSubHeader'> &
  Pick<Sub, 'isSubHeader'> &
  Pick<Main, 'jobIdsWithSubJobs' | 'selectedRows'>;
export function useHeader({
  canTablePossiblyHaveADeletableRow,
  doesTableHaveACopyableRow,
  isSubHeader,
  jobIdsWithSubJobs,
  selectedRows,
  totalRowCount,
}: useHeaderProps) {
  const selectedRowsLength = selectedRows.length || 0;

  const { isAssignJobTable, isSubJobsTable, jobType, preferencePrefix } = usePreferencePrefix();

  const pagePreference = usePagePreference({ preferencePrefix, totalRowCount });
  const rowsPerPage = usePreferenceAsNumber({
    code: `${preferencePrefix}.rowsPerPage`,
    defaultValue: '25',
  });
  const lastPage = Math.floor(totalRowCount / rowsPerPage.value);
  const rowsOnLastPage = totalRowCount % rowsPerPage.value;
  const isIndeterminate = selectedRowsLength
    ? selectedRowsLength === rowsPerPage.value ||
      (pagePreference.value === lastPage && rowsOnLastPage === selectedRowsLength)
      ? false
      : true
    : false;
  const isChecked = !isIndeterminate && !!selectedRowsLength;

  const subHeaderBackgroundColorStyling = isSubHeader
    ? { sx: { bgcolor: 'primary.main' as const } }
    : {};
  const areRowsSelectable =
    jobType !== 'dash' && !isSubJobsTable && !isAssignJobTable && !isSubHeader;
  const shouldHaveExpandCollapseColumn = !!jobIdsWithSubJobs.length;
  const shouldHaveCopyColumn =
    !isSubHeader && jobType !== 'dash' && !isAssignJobTable && doesTableHaveACopyableRow;
  const shouldHaveDeleteColumn =
    !isSubHeader &&
    !isSubJobsTable &&
    jobType !== 'dash' &&
    !isAssignJobTable &&
    canTablePossiblyHaveADeletableRow;

  return {
    areRowsSelectable,
    isChecked,
    isIndeterminate,
    shouldHaveCopyColumn,
    shouldHaveDeleteColumn,
    shouldHaveExpandCollapseColumn,
    subHeaderBackgroundColorStyling,
  };
}
