import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, CircularProgress, IconButton } from '@mui/material';

import { BreadCrumbs } from 'src/components/BreadCrumbs';
import {
  useCompareFilesQuery,
  useGetApprovalsQuery,
  useGetFilesQuery,
} from 'src/features/Approvals/Approvals.service';
import { ActionBar } from 'src/features/Approvals/components/ActionBar';
import { useGetProofscopeFileQuery } from 'src/features/FileExplorer/FileExplorer.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { useLazyNotifyBackendQuery } from 'src/pages/Viewer/ViewerCallback.service';
import {
  useAppSelector,
  useIsExternalRevisor,
  useRouteParams,
  useSelect,
} from 'src/utilities/hooks';
import { useFullScreen } from 'src/utilities/hooks/FullScreenContext';
import { parseArrayToLogicalObject } from 'src/utilities/parsers';

export function Viewer() {
  const { age, jobId, jobType } = useRouteParams();

  const { handleChangeFullScreen, isFullScreen, setIsFullScreen } = useFullScreen();
  const [isFrameLoading, setIsFrameLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const userId = useAppSelector((state) => state.user.details.id);

  const queryParams = new URLSearchParams(location.search);
  const fileId = queryParams.get('fileId');
  const sourceAge = queryParams.get('age');
  const tab = queryParams.get('tab');
  const compareFile = queryParams.get('compareFile');
  const pathname = location.pathname;
  const token = pathname.split('/')[2];

  const isExternalRevisorView = useIsExternalRevisor();
  const [notifyBackend] = useLazyNotifyBackendQuery();

  const { data: { url } = {} } = useGetProofscopeFileQuery(
    {
      age,
      fileId: Number(fileId),
      jobId,
      jobType,
    },
    { skip: compareFile === 'true' },
  );

  const { data: compareUrl } = useCompareFilesQuery(
    {
      age,
      file_id: fileId?.split(',').map(Number),
      jobid: jobId,
      src: jobType,
    },
    { skip: compareFile === 'false' },
  );

  const {
    data: approvals = [],
    isLoading,
    isSuccess: areApprovalsLoadedSuccessfully,
  } = useGetApprovalsQuery({
    age,
    jobid: jobId,
    src: jobType,
  });

  const { data: files = { categorizedFiles: {}, rawFiles: [] } } = useGetFilesQuery({
    age,
    jobId,
    src: jobType,
  });

  const { selectedItemIds } = useSelect(parseArrayToLogicalObject(files.rawFiles, 'id'));

  function handleCloseProofscopePage() {
    if (isFullScreen) handleChangeFullScreen();

    notifyBackend({
      age,
      id: userId,
      jobid: jobId,
      src: jobType,
    }).then(() => {
      if (isExternalRevisorView) {
        navigate(`/external-revisor/${token}/jobs-job-${jobType}/${jobId}`, { replace: true });
      } else {
        navigate(
          sourceAge
            ? `/jobs-hom-${sourceAge}`
            : tab === 'compare'
            ? `/jobs-job-${jobType}/${jobId}/fil`
            : `/jobs-job-${jobType}${tab ? `/${jobId}/${tab}` : ''}`,
          { replace: true },
        );
      }
    });
  }

  useEffect(() => {
    if (!isFullScreen) {
      setIsFullScreen(true);
    }
  }, []);

  return (
    <>
      {!isExternalRevisorView ? <BreadCrumbs /> : null}

      <Box alignItems="center" display="flex" justifyContent="space-between" pt={2} px={1}>
        <Box pb={2}>
          {!isExternalRevisorView ? (
            <Button
              onClick={handleChangeFullScreen}
              startIcon={<WaveIcon code={isFullScreen ? 'exit-fullscreen' : 'fullscreen'} />}
              variant="outlined"
            >
              {isFullScreen ? 'Exit Fullscreen' : 'Show Fullscreen'}
            </Button>
          ) : null}
        </Box>

        <Box alignItems="center" display="flex" gap={1}>
          {areApprovalsLoadedSuccessfully && !approvals.length && !isLoading ? null : (
            <ActionBar selectedFileIds={selectedItemIds as number[]} />
          )}

          <Box pb={2}>
            <IconButton onClick={handleCloseProofscopePage}>
              <WaveIcon code="close" />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box
        alignItems="center"
        display="flex"
        height="calc(100vh - 72px)"
        justifyContent="center"
        position="relative"
      >
        {isFrameLoading && (
          <Box position="absolute">
            <CircularProgress className="spinner-progress" size={100} />
          </Box>
        )}

        <iframe
          className="border-none"
          height="100%"
          onLoad={() => setIsFrameLoading(false)}
          src={compareFile === 'true' ? compareUrl : url}
          width="100%"
        />
      </Box>
    </>
  );
}
