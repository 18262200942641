import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';
import { ExpandedAges } from '../../utilities/hooks/useRouteParams';

export type ViewerCallbackParams = {
  id?: number;
  src?: string;
  jobid?: number | string;
  age?: ExpandedAges;
};

export const viewerCallbackApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    notifyBackend: build.query<void, ViewerCallbackParams>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/close-viewer',
        };
      },
    }),
  }),
  reducerPath: 'viewerCallbackApi',
});

export const { useLazyNotifyBackendQuery } = viewerCallbackApi;
