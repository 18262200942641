import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';

import { TableButtonCell } from 'src/components/TableButtonCell';
import { useHeader } from 'src/features/JobsTable/components/Header/useHeader';
import { HeaderCell } from 'src/features/JobsTable/components/HeaderCell';
import {
  Assignment as AssignmentJobsTable,
  Common as CommonJobsTable,
  Dashboard as DashboardJobsTable,
  Main as MainJobsTable,
  WithoutExpandableRows as JobsTableWithoutExpandableRows,
  WithoutSelectableRow as JobsTableWithoutSelectableRow,
} from 'src/features/JobsTable/JobsTable';

type Common = Pick<CommonJobsTable, 'columns'>;
export type IsNotSub = {
  canTablePossiblyHaveADeletableRow: boolean;
  doesTableHaveACopyableRow: boolean;
  isSubHeader?: never;
} & Pick<CommonJobsTable, 'totalRowCount'>;
type WithoutSelectableRow = Pick<
  JobsTableWithoutSelectableRow,
  'onToggleSelectAllRows' | 'selectedRows'
>;
type NeededProperty = 'jobIdsWithSubJobs' | 'onToggleSelectAllRows' | 'selectedRows';
export type Main = IsNotSub & Pick<MainJobsTable, NeededProperty>;
type Dashboard = IsNotSub & Pick<DashboardJobsTable, NeededProperty>;
type Assignment = IsNotSub & Pick<AssignmentJobsTable, NeededProperty>;
export type Sub = {
  canTablePossiblyHaveADeletableRow?: never;
  doesTableHaveACopyableRow?: never;
  isSubHeader: boolean;
  totalRowCount?: never;
} & WithoutSelectableRow &
  Pick<JobsTableWithoutExpandableRows, 'jobIdsWithSubJobs'>;
type HeaderProps = Common & (Main | Dashboard | Assignment | Sub);

export function Header({
  canTablePossiblyHaveADeletableRow = false,
  columns,
  doesTableHaveACopyableRow = false,
  isSubHeader = false,
  jobIdsWithSubJobs = [],
  onToggleSelectAllRows,
  selectedRows = [],
  totalRowCount = 0,
}: HeaderProps) {
  const {
    areRowsSelectable,
    isChecked,
    isIndeterminate,
    shouldHaveCopyColumn,
    shouldHaveDeleteColumn,
    shouldHaveExpandCollapseColumn,
    subHeaderBackgroundColorStyling,
  } = useHeader({
    canTablePossiblyHaveADeletableRow,
    doesTableHaveACopyableRow,
    isSubHeader,
    jobIdsWithSubJobs,
    selectedRows,
    totalRowCount,
  });

  return (
    <TableHead>
      <TableRow>
        <TableCell {...subHeaderBackgroundColorStyling} />

        {shouldHaveExpandCollapseColumn ? <TableButtonCell /> : null}

        <TableButtonCell muiTableCellProps={subHeaderBackgroundColorStyling} paddingY={0}>
          {areRowsSelectable ? (
            <Checkbox
              checked={isChecked}
              indeterminate={isIndeterminate}
              size="small"
              {...(isIndeterminate && { color: 'secondary' })}
              {...(onToggleSelectAllRows && { onChange: onToggleSelectAllRows })}
            />
          ) : null}
        </TableButtonCell>

        {
          <>
            {shouldHaveCopyColumn ? <TableButtonCell /> : null}

            {shouldHaveDeleteColumn ? <TableButtonCell /> : null}

            {columns.map(({ fieldAlias, fieldName }) => (
              <HeaderCell
                fieldAlias={fieldAlias}
                fieldName={fieldName}
                key={fieldAlias}
                {...(isSubHeader && { isSubHeader })}
              />
            ))}
          </>
        }
      </TableRow>
    </TableHead>
  );
}
