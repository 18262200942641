import { PreviewCellProps } from 'src/features/JobsTable/components/PreviewCell/PreviewCell';
import { useViewer } from 'src/utilities/hooks';

import { RowJobType } from 'src/features/JobsTable/types';

type UsePreviewCellProps = Pick<PreviewCellProps, 'jobId' | 'preview'> & RowJobType;

export function usePreviewCell({ jobId, preview, rowJobType }: UsePreviewCellProps) {
  const { openViewerPage } = useViewer();

  function handleClickThumbnailContainer(e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) {
    e.stopPropagation();
    e.preventDefault();

    if (preview.url !== '') {
      openViewerPage(preview?.fileId || 0, jobId, false, rowJobType);
    }
  }

  return { handleClickThumbnailContainer };
}
